import React from 'react'

import { Helmet as _Helmet } from 'react-helmet'
import { SpeedTest } from '../../pages/products/zenlayer-global-accelerator'
import '../../styles/gia.less'

const Helmet: any = _Helmet

export default function Home() {
  return (
    <div className="gia">
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <SpeedTest isWidget={true} />
    </div>
  )
}
